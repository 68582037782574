import React from "react"
import WebsiteLayout from "../../components/layout/website/website-layout/website-layout"
import SEO from "../../services/seo"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./index.module.scss"

const PrivacyPage = () => {
  const data = useStaticQuery(graphql`
    query PagePrivacyFHAAQuery {
      allStrapiPrivacy {
        edges {
          node {
            title
            content
          }
        }
      }
    }
  `)
  console.log("PrivacyPage:", data)
  return (
    <WebsiteLayout>
      <SEO title={data.allStrapiPrivacy.edges[0].node.title} />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          {data.allStrapiPrivacy.edges[0].node.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.allStrapiPrivacy.edges[0].node.content,
          }}
        />
      </div>
    </WebsiteLayout>
  )
}

export default PrivacyPage
